<template>
   <div >
    <v-banner
        class="lavBanner" 
        single-line
        height="300"
    >
      <v-img
          src="https://h5.ophyer.cn/official_website/banner/storeSolution-banner.png"
          height="100%"
          class="d-flex align-center"
        >
        <template v-slot:placeholder>
          <v-row class="fill-height ma-0" align="center" justify="center">
            <v-progress-circular
              indeterminate
              size="80"
              color="red lighten-5"
            ></v-progress-circular>
          </v-row>
        </template>
        <v-container color="transparent" class="d-flex justify-center">
          <v-card color="transparent" flat width="80%">
            <div class="d-flex flex-no-wrap justify-center" style="color: #fff">
              <div>
                <v-card-title class="white--text font-size-banner-title font-weight-bold px-0">
                  给自己一次机会
                </v-card-title>
                <v-card-title class="white--text font-size-banner-tip px-0" style="line-height:30px;margin:0 auto;text-align: center;display: block;">
                  和他们一样，一起加入我们，领跑电商行业
                </v-card-title>
              </div>
            </div>
          </v-card>
        </v-container>
      </v-img>
    </v-banner>
    <v-container class="containerStyle" height='500' style="background: rgb(245, 248, 253);">
      <div class="public-title">
        <p>WORD OF MOUTH CASES</p>
        <div>
          <span>口碑案例</span>
        </div>
      </div>
      <v-card class="wrap part-one d-flex justify-center" flat>
        <div class=" borderimg" >
          <img src="https://h5.ophyer.cn/official_website/other/storeCase1.png"/>
        </div>
        <div class="text-wrap" style="padding-right:100px">
          <v-card class="pa-0" flat>
            <v-card-title  class="con_tit pa-0 font-size-24">熹谷 – 新生代青瓷茶具品牌</v-card-title>
            <v-card-title  class="pa-0 font-size-18 font-weight-bold mt-4">广告运营+CPS分销助力订单高效转化品牌</v-card-title>
            <!-- <v-card-title  class="font-size-14 pa-0 mb-5">专注厨卫消杀</v-card-title> -->
            <!-- <v-card-text  class="con_text pa-0 mb-5"></v-card-text> -->
            <v-card-text  class="con_text pa-0 mt-5">结合618短期爆发的消费特点，为熹谷推出了一套CPS推广方案。熹谷DAU指数实现爆炸式增长，参与CPS推广的14天里增幅就超过70倍，加上通过数万社群的推广和覆盖，熹谷CPS订单数占据店铺总订单数的70%。</v-card-text> 
          </v-card>
        </div>
      </v-card>
      <v-card class="wrap part-one d-flex flex-no-wrap justify-center flex-row-reverse" flat>
        <div class="pl-10 borderimg_two">
          <img src="https://h5.ophyer.cn/official_website/other/storeCase2.png" />
        </div>
        <div class="text-wrap" style="padding-left:60px;padding-right:80px">
          <v-card class="pa-0"  flat>
            <v-card-title  class="con_tit pa-0 font-size-24">HOYO – 日本高端家纺品牌</v-card-title>
            <v-card-title  class="pa-0 font-size-18 font-weight-bold mt-4">打造私域流量“护城河”，赢战618</v-card-title>
            <v-card-text  class="con_text pa-0 mt-5 mb-5">使用万目云电商商城后，小程序商城上线两周内，SKU从0到300+。 618期间HOYO广告点击率大幅提升，跳失率由98.75%降至57.5%；两周内店铺成交转化率提升至6.17%；客单价由49提高至1020，增长率高达1982%。</v-card-text>
            <!-- <v-card-text  class="con_text pa-0 "></v-card-text> -->
          </v-card>
        </div>
      </v-card>
      <v-card class="wrap part-one d-flex justify-center mt-10" flat>
        <div class=" borderimg" >
          <img src="https://h5.ophyer.cn/official_website/other/storeCase3.png"/>
        </div>
        <div class="text-wrap" style="padding-right:100px">
          <v-card class="pa-0" flat>
            <v-card-title  class="con_tit pa-0 font-size-24">格玛仕 – 时尚腕表品牌</v-card-title>
            <v-card-title  class="pa-0 font-size-18 font-weight-bold mt-4"></v-card-title>
            <v-card-title  class="font-size-14 pa-0 mb-5">提升品牌影响力 销量增长超6倍</v-card-title>
            <v-card-text  class="con_text pa-0 mb-5">情人节期间广告ROI达到5.0；格玛士还将社交平台、中心化电商平台的流量导向社群，建立私域流量池，2个月的时间里，销量增长超过6倍。</v-card-text>
            <v-card-text  class="con_text pa-0"></v-card-text>
          </v-card>
        </div>
      </v-card>
    </v-container>
    <!-- 服务客户 -->
    <div class="d-flex justify-center pb-5" >
      <v-card class="max-width-1200" width="100%" :flat="true" color="transparent">
        <div class="public-title" style="margin-top: 60px;margin-bottom:10px">
          <p>MERCHANT SERVICES</p>
          <div>
            <span>服务客户</span>
          </div>
        </div>
        <v-img class="mt-n5" contain src="https://h5.ophyer.cn/official_website/other/storeCase.png"/>
      </v-card>
    </div>
   </div>
</template>

<script>

export default {
  name: "OnlineRetailersCase",
  data(){
    return{
      model: 0,
      bannerLoad: false,
      imgLoad: false,
      windowHight: window.innerHeight/2,
    }
  },
  mounted(){
    this.bannerLoad = true;
  },
  methods: {
    handleLoad (){
      this.imgLoad = true;
    },
  }
}
</script>

<style scoped lang='scss'>
.lavBanner {
  ::v-deep .v-banner__wrapper,
  ::v-deep .v-banner__content,
  ::v-deep .v-banner__text {
    padding: 0 !important;
    height: 100%;
  }
}

::v-deep .v-banner__wrapper{
  padding: 0 !important;
}
.containerStyle{
  padding-top: 24px;
  padding-bottom: 4px;
  .v-card__title{
    line-height: 26px !important;
  }
  .borderimg{
    img{
      border-radius: 10px;
      float: right;
      width: 500px;
      height: 340px;
      margin: 60px 80px 60px 60px;
    }
  }
  .borderimg_two{
    width:600px;
    height:500px;
    img{
      border-radius: 10px;
      float: left;
      width: 500px;
      height: 340px;
      //margin-top: 80px;
      margin: 60px 60px 60px 0px;
    }
  }
  .part-one{
    width: 1120px;
    margin-bottom: 21px;
    background: #FFFFFF;
    border-radius: 15px;
    margin-bottom: 60px;
    .text-wrap{
      width: 600px;
      .con_tit{
        font-size: 24px;
        font-weight: bold;
        color: #333333;
        line-height: 30px;
        margin-top:178px
      }
      .con_text{
        height: 40px;
        font-size: 14px;
        font-weight: 400;
        color: #666;
        line-height: 20px;
      }
      .banner-btn {
        margin-top: 50px;
        width: 160px;
        height: 50px;
        background-color: #0568FD;
        border-radius: 25px;
        font-size: 20px !important;
        font-weight: 400;
        color: #FFFFFF;
        line-height: 50px;
        text-align: center;
        cursor: pointer;
      }
    }
  }
}
::v-deep.v-application--wrap{
  background: rgb(245, 248, 253) !important;
}
</style>